.barImg{
   

    margin-top: -320px;
}
.barImg img{
    width: 200px;
}
@media(min-width:300px) and (max-width:600px){
    .barImg{
        margin-top: -220px;
        /* border: 2px solid red; */
    }
    
}